<script>
import { mapState } from 'vuex'

import { toastMixins } from '@/mixins'
import authService from '@/services/auth/auth'

import MenuBar from './components/MenuBar.vue'

import CreateEditForm from '../components/CreateEditForm.vue'

export default {
  mixins: [toastMixins],
  components: { MenuBar, CreateEditForm },
  data() {
    return {
      isLoading: false,
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      defaultDelayMs: (state) => state.constants.defaultDelayMs
    })
  },
  created() {},
  methods: {
    async createdInfoHandler(payload) {
      this.isLoading = true
      try {
        const body = {
          ...payload,
          happysoftSignupCode: process.env.VUE_APP_SIGNUP_CODE
        }
        await authService.signup(body)
        this.mxDisplaySuccessMessage('สร้างผู้ใช้งานสำเร็จ')
        setTimeout(() => {
          this.$router.push(`/admin/user-logon-masters`)
        }, this.defaultDelayMs)
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
      this.isLoading = false
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- แถบ menu ด้านบน -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="สร้างผู้ใช้งานรายใหม่"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar></menu-bar>
      </b-col>
    </b-row>

    <!-- create form -->
    <create-edit-form
      modeProp="CREATE"
      :isLoadingProp="isLoading"
      @createdInfo="createdInfoHandler"
    ></create-edit-form>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
